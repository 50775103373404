<script setup lang="ts">
const { t, locale, defaultLocale }: any = useI18n()

const selectedIcon = computed(() => `/${locale.value}.svg`)

const localeItems = [
  [{
    label: 'English',
    avatar: {
      src: `/en.svg`,
    },
    click: async () => {
      await navigateTo(defaultLocale === 'en' ? '/' : '/en', { external: true })
    },
  }, {
    label: 'Tiếng Việt',
    avatar: {
      src: '/vi.svg',
    },
    click: async () => {
      await navigateTo(defaultLocale === 'vi' ? '/' : '/vi', { external: true })
    },
  }],
]
</script>

<template>
  <UDropdown
    :items="localeItems"
    :ui="{ item: { disabled: 'cursor-text select-text', padding: 'px-1.5 py-2', size: 'text-base' } }"
    :popper="{ placement: 'bottom-start' }"
    class="py-3 sm:my-5 sm:my-0"
  >
    <UAvatar :src="selectedIcon" alt="" size="sm" />

    <span class="sm:hidden truncate text-base px-3 text-gray-600">{{ t('language') }}</span>
  </UDropdown>
</template>
