<script setup lang="ts">
const { t } = useI18n()

const sitedata: any = useSiteData()

const columns = [
  {
    name: 'name',
    label: t('fullname'),
    field: 'name',
    type: 'string',
    validate: [
      'required',
    ],
  },
  {
    name: 'email',
    label: t('email'),
    field: 'email',
    type: 'email',
    validate: [
      'required',
    ],
  },
]
</script>

<template>
  <div v-if="sitedata?.newsletter" class="print:hidden relative flex min-h-48 flex-col items-center justify-center bg-gray-50 p-5 md:p-16">
    <div class="w-full max-w-4xl border bg-white p-8 md:p-14 overflow-hidden shadow-md rounded-2xl">
      <div class="flex flex-col items-center">
        <h3 class="mt-2 max-w-2xl text-center text-2xl font-bold leading-tight sm:text-3xl md:text-4xl md:leading-tight">
          {{ t('subscribe_newsletter') }}
        </h3>

        <FormInput
          :columns="columns"
          :accept-checkbox="true"
          submit-uri="hcsxxkvpan"
          :submit-label="t('subscribe')"
          :success-title="t('subscribed')"
        />
      </div>
    </div>
  </div>
</template>
